import { graphql, useStaticQuery } from "gatsby"
import { set } from "lodash"
import { noCase } from "no-case"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import { HeaderListCountry, ListOfCountry, Toggler } from "./components"
import { NakedSearchInput } from "./search"

export const CountryList = ({ isEnglish }) => {
  const data = useStaticQuery(graphql`
    {
      list: allStrapiListOfCountries {
        nodes {
          id
          Active
          Available
          slug
          country_en
          country_id
          continent_en
          continent_id
          estimate_en
          estimate_id
          price
          limit
          iso
          iso_3
        }
      }
    }
  `)

  const [toggleActive, setToggleActive] = useState("all")
  const [search, setSearch] = useState("")

  const countryListEn = [...data?.list?.nodes].sort((a, b) =>
    a.country_en > b.country_en ? 1 : b.country_en > a.country_en ? -1 : 0
  )
  const countryListId = [...data?.list?.nodes].sort((a, b) =>
    a.country_id > b.country_id ? 1 : b.country_id > a.country_id ? -1 : 0
  )
  const countryListArrayRaw = isEnglish ? countryListEn : countryListId

  const countryListArray = [...countryListArrayRaw].filter(
    item =>
      noCase(item.country_en).includes(noCase(search)) ||
      noCase(item.country_id).includes(noCase(search))
  )

  const handleToggler = value => setToggleActive(value)
  const handleSearch = value => {
    setToggleActive("all")
    setSearch(value)
  }

  return (
    <>
      <Container style={{ marginTop: 200, marginBottom: 200 }}>
        <HeaderListCountry isEnglish={isEnglish} />
        <NakedSearchInput
          placeholder={
            isEnglish ? "Search destination country" : "Cari negara tujuan"
          }
          onChange={handleSearch}
        />
        <Toggler
          onClick={handleToggler}
          active={toggleActive}
          isEnglish={isEnglish}
        />
        <ListOfCountry
          search={search}
          data={countryListArray}
          isEnglish={isEnglish}
          active={toggleActive}
        />
      </Container>
    </>
  )
}
