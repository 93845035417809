import React from "react"
import {useStateIfMounted} from 'use-state-if-mounted'

import {
  CountriesHeader,
  CountriesContainer,
  CountriesDiv,
  CountriesH2,
  CountriesP,
} from "./HeaderStyle"
import bgCountries from "../../images/countries-globe.png"
import NavigationBar from "./NavigationBar"

function HeaderCountries({ props, propsLink, propsData }) {
  const [statusNav, setStatusNav] = useStateIfMounted(false)
  const { Title, Desc } = propsData.Header

  const statusNavbar = status => {
    setStatusNav(status)
  }
  
  return (
    <CountriesHeader
      background={bgCountries}
      position="center"
      style={statusNav ? {paddingTop: "80px"}:null}
    >
      <NavigationBar
        props={props}
        propsLink={propsLink}
        parentCallback={statusNavbar}
      />
      <CountriesContainer>
        <CountriesDiv className="d-flex flex-column p-4">
          <CountriesH2 fsres="32px">{Title}</CountriesH2>
          <CountriesP fsres="25px">{Desc}</CountriesP>
        </CountriesDiv>
      </CountriesContainer>
    </CountriesHeader>
  )
}

export default HeaderCountries
