export const filterDataByRegion = (data, active) => {
  if (active === "all") return data
  
  if (active === "asia")
  return data.filter(({ continent_en }) => continent_en === "Asia")
  
  if (active === "europe")
  return data.filter(({ continent_en }) => continent_en === "Europe")
  
  if (active === "america")
  return data.filter(({ continent_en }) => continent_en === "America")
  
  if (active === "africa")
  return data.filter(({ continent_en }) => continent_en === "Africa")

  return data
}
