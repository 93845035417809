import React from "react"
import styled from "styled-components"

export const NakedSearchInput = ({ onChange, placeholder = "" }) => {
  const handleChange = e => onChange(e.target.value)
  return (
    <div className="my-5">
      <StyledInput
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  )
}

const StyledInput = styled.input`
  padding: 12px 12px 12px 30px;
  font-family: "Typefez-med";
  border: 1px solid #db64a0;
  border-radius: 10px;
  width: 450px;
  ::placeholder {
    color: #c4c4c4;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`
