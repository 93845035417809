import React from "react"
import "../components/layout.css"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

// Import Component
import Footer from "../components/FooterComponents/Footer"
import HeaderCountries from "../components/HeaderComponents/HeaderCountries"

// Import Json
import { MainPageJson, PathJson, CountriesJson } from "../components/location"
import Download from "../components/HomeComponents/Download"
import BlogContainer from "../components/HomeComponents/BlogContainer"
import Testimonies from "../components/HomeComponents/Testimonies"
import { HowToUse } from "../components/HomeComponents/HowToUse"
import { HowItWorks } from "../components/HomeComponents/HowItWorks"
import FeatureSection from "../components/FeatureSection/FeatureSection"
import { NavigationBar } from "../components/NavigationBar/parent"
import { Header } from "../components/Countries/Header"
import { CountryList } from "../components/Countries/List/parent"
import Seo from "../components/SEOComponents/Seo"

function Countries() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const CountriesContent = CountriesJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <>
      <Seo title="countries" />
      <NavigationBar />
      <Header
        props={MainPageContent.Header}
        propsLink={PathContent}
        isEnglish={isEnglish}
      />
      <CountryList isEnglish={isEnglish} />
      <FeatureSection props={MainPageContent.Advantages} />
      <HowItWorks props={MainPageContent.HowItWorks} isEnglish={isEnglish} />
      <HowToUse props={MainPageContent.HowToUse} />
      <Testimonies />
      <BlogContainer isEnglish={isEnglish} />
      <Download props={MainPageContent.Download} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </>
  )
}

export default Countries
