import React, { useEffect, useState } from "react"
import { noCase } from "no-case"
import { CountryFlag } from "../../Calculator/CalculatorStyle"
import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import { filterDataByRegion } from "./logic"
import { navigate } from "gatsby"

export const HeaderListCountry = ({ isEnglish }) => {
  const title = isEnglish
    ? `Send Money to Over 70 Countries`
    : `Kirim Uang ke Lebih dari 70 Negara`
  const desc = isEnglish
    ? `Find your destination to send money from Indonesia in this list of countries`
    : `Temukan tujuan pengiriman uang dari Indonesia di daftar negara berikut ini`

  return (
    <>
      <TitleH1>{title}</TitleH1>
      <DescCustom>{desc}</DescCustom>
    </>
  )
}

export const ListOfCountry = ({ data: dataRaw, isEnglish, active, search }) => {
  const [data, setData] = useState([])

  const handleClick = item => {
    const { slug, Available } = item || {}
    if (Available) {
      navigate(`${isEnglish ? "/en" : ""}/countries/send-money-to-${slug}`)
    }
  }

  useEffect(() => {
    const finalData = filterDataByRegion(dataRaw, active)
    setData(finalData)
  }, [active, search])

  return (
    <div
      style={{
        padding: 60,
        backgroundColor: "white",
        boxShadow: "0px 10px 30px rgba(15, 44, 87, 0.15)",
        borderRadius: "0px 0px 20px 20px",
        position: "relative",
      }}
    >
      <Row>
        {data?.map((item, index) => {
          const { iso, country_en, country_id, Available, slug } = item || {}
          const nameCountry = isEnglish ? country_en : country_id
          return (
            <Col md={3} xs={6} key={index}>
              <div
                className="d-flex"
                style={{
                  gap: 8,
                  cursor: Available && "pointer",
                  marginBottom: 8,
                }}
                onClick={() => handleClick(item)}
              >
                <CountryFlag
                  src={`https://flagcdn.com/h80/${noCase(iso || "")}.png`}
                  alt="country-calculator"
                />
                <ActiveCountry
                  color={!Available && "#A6A6AC"}
                  decor={Available && "underline"}
                >
                  {nameCountry}
                </ActiveCountry>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export const Toggler = ({ onClick, active, isEnglish }) => {
  const data = [
    { title_en: "All Region", title_id: "Semua Region", type: "all" },
    { title_en: "Asia Pasific", title_id: "Asia Pasifik", type: "asia" },
    { title_en: "Europe", title_id: "Eropa", type: "europe" },
    { title_en: "America", title_id: "Amerika", type: "america" },
    { title_en: "Africa", title_id: "Afrika", type: "africa" },
  ]

  return (
    <div>
      <MainResponsiveToggleDiv>
        {data?.map((item, index) => {
          const { title_en, title_id, type } = item || {}
          const isActive = active === type

          const title = isEnglish ? title_en : title_id
          return (
            <TogglerResDiv
              key={index}
              onClick={() => onClick(type)}
              bcolor={isActive && "#da64a0"}
            >
              <TogglerTitle color={isActive && "white"}>{title}</TogglerTitle>
            </TogglerResDiv>
          )
        })}
      </MainResponsiveToggleDiv>
      <MainTogglerDiv>
        {data?.map((item, index) => {
          const isLast = index === data.length - 1
          const { title_en, title_id, type } = item || {}
          const isActive = active === type

          const title = isEnglish ? title_en : title_id

          const bshadow =
            (isActive && "0px 10px 30px rgba(15, 44, 87, 0.15)") ||
            (isLast && "none")

          return (
            <div
              style={{ width: "20%" }}
              key={index}
              onClick={() => onClick(type)}
            >
              <TogglerDiv bshadow={bshadow} bcolor={isActive && "white"}>
                <TogglerTitle>{title}</TogglerTitle>
              </TogglerDiv>
            </div>
          )
        })}
      </MainTogglerDiv>
    </div>
  )
}

const MainTogglerDiv = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: -15px;
  @media (max-width: 768px) {
    display: none;
  }
`

const MainResponsiveToggleDiv = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const TitleH1 = styled.h2`
  font-size: 50px;
  color: #102c57;
  padding-right: 20%;
  font-family: "Typefez-black";
  @media (min-width: 768px) {
    text-align: left;
  }
`

const DescCustom = styled.p`
  font-size: 18px;
  line-height: 24px;
  padding-right: 30%;
  color: #333334;
  font-family: "Typefez-reg";
`

const ActiveCountry = styled.p`
  font-size: 14px;
  font-family: "Typefez-bold";
  color: ${p => p.color || "#db64a0"};
  :hover {
    text-decoration: ${p => p.decor};
  }
`

const TogglerDiv = styled.div`
  background-color: ${p => p.bcolor || "#102c57"};
  cursor: pointer;
  border-radius: 15px 15px 0px 0px;
  padding: 15px 18px 30px 18px;
  box-shadow: ${p => p.bshadow || "10px 10px 15px rgba(219, 100, 160, 0.45)"};
  @media (max-width: 768px) {
    margin-bottom: -10px;
  }
`

const TogglerResDiv = styled.div`
  height: 40px;
  background-color: ${p => p.bcolor || "white"};
  white-space: nowrap;
  padding: 6px 18px;
  border: 1px solid #da64a0;
  border-radius: 20px;
  cursor: pointer;
`

const TogglerTitle = styled.p`
  color: ${p => p.color || "#da64a0"};
  font-family: "Typefez-bold";
  margin-bottom: 0px;
  text-align: center;
`
