import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import _Calculator from "../Calculator/Calculator"
import Legal from "../HomeComponents/Legal"
import { StoreLinks } from "../HomeComponents/StoreLinks"
import { graphql, useStaticQuery } from "gatsby"

export const Header = ({ props, propsLink, isEnglish }) => {
  const data = useStaticQuery(graphql`
    query {
      headerBackground: file(relativePath: { eq: "header-countries-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imagesBg = data.headerBackground.childImageSharp.fluid

  const title = isEnglish
    ? `Send Money Abroad from Indonesia with Transfez`
    : `Kirim uang dari Indonesia dengan Transfez`
  const desc = isEnglish
    ? `Feel the ease to move your money across the globe
  fast, safe and convenient.`
    : `Nikmati kemudahan kirim uang ke berbagai negara
  cepat, aman dan nyaman. `

  return (
    <MainPageHeader style={{ paddingTop: "80px" }}>
      <Container className="pt-5 position-relative">
        <MainImage
          fluid={imagesBg}
          alt="header background"
          loading="eager"
          imgStyle={{ objectFit: "contain" }}
        />
        <Row>
          <Col md={7}>
            <TitleH1> {title}</TitleH1>
            <DescCustom> {desc}</DescCustom>
          </Col>
          <CustomColCalc md={5}>
            <Calculator propsLink={propsLink} />
            <LegalDiv>
              <Legal props={props} />
              <StoreLinks className="mt-3" />
            </LegalDiv>
          </CustomColCalc>
        </Row>
      </Container>
    </MainPageHeader>
  )
}

const MainPageHeader = styled.div`
  min-height: 800px;
  padding-bottom: 40px;
  @media (max-width: 576px) {
    padding-bottom: 150px !important;
  }
`

const MainImage = styled(Img)`
  position: absolute !important;
  width: 800px;
  left: -8% !important;
  top: 45% !important;
  @media (max-width: 768px) {
    display: none;
  }
`

const TitleH1 = styled.h2`
  font-size: 50px;
  color: #102c57;
  padding-right: 20%;
  font-family: "Typefez-black";
  @media (max-width: 768px) {
    font-size: 35px;
    padding-right: 0px;
    margin-bottom: 20px;
  }
`

const DescCustom = styled.p`
  font-size: 18px;
  line-height: 24px;
  padding-right: 30%;
  color: #333334;
  font-family: "Typefez-reg";
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const LegalDiv = styled.div`
  position: absolute;
  top: 96%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  align-items: center;
  @media (max-width: 1200px) {
    padding-left: 0px;
  }
  @media (max-width: 768px) {
    padding-left: 130px;
  }
  @media (max-width: 576px) {
    padding-left: 10px;
  }
`

const CustomColCalc = styled(Col)`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 576px) {
    align-items: center;
    margin-top: 50px;
  }
`

const Calculator = styled(_Calculator)`
  position: "absolute";
  z-index: 10;
`
